import React  from 'react'
import { Link } from 'gatsby'
import { FaStar,FaMailBulk, FaInstagram, FaFacebookSquare } from 'react-icons/fa';
 
// Styles
import { FooterWrapper, FooterWrapperSociala, FooterBottom } from "./Footer.styles"

const Footer = () => { 
    return(
        <>
            <FooterWrapper>   
                <div className="links">
                    <h3>Kontakt</h3>
                    <Link to="/kontakt">Kontakt</Link>
                    <a href="mailto:info@restaurangerboras.se"> info@restaurangerboras.se</a><br/>
                </div>
                <FooterWrapperSociala className="kontakt">
                    <h3>Sociala Medier</h3>
                    <div className="sociala-medier">
                        <a href="https://www.facebook.com/restaurangerboras.se" className="social-knapp">
                            <p>face</p>
                            <FaFacebookSquare />
                        </a> 
                        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                            <p>insta</p>
                            <FaInstagram />
                        </a>
                        <a href="mailto:info@restaurangerboras.se" target="_blank" rel="noopener noreferrer">
                            <p>mail</p>
                            <FaMailBulk />
                        </a>
                    </div>
                </FooterWrapperSociala>
                <div className="tider">
                <h3>GDPR</h3>
                    <Link to="/cookiepolicy">Cookiepolicy</Link><br/>
                    <Link to="/sekretesspolicy">Sekretesspolicy</Link>

                </div>    
            </FooterWrapper>
            <FooterBottom>
                <h3>En klick ifrån alla restauranger i Borås</h3>
                <div className="starholder"> 
                    <div className="left">
                        <FaStar />
                        <p>Vi hjälper kunder att hitta din restaurang, enkelt!</p>
                    </div>
                    <div className="center">
                        <FaStar /> 
                        <p>Kontakta oss så ansluter vi din restaurang också!</p>
                    </div>
                    <div className="right">
                        <FaStar /><br/>
                        <a style={{color:"white"}} href="https://www.indexija.com">Made by Indexija</a><br/>
                        <a style={{color:"white"}} href="https://www.aklejakvinnoklinik.se/malmo/ultraljud-malmo/">Mer från Borås</a>

                    </div>
                </div>
            </FooterBottom>
        </>
    )
}
export default Footer
